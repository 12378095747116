import "./App.css";
import { CenteredTabs } from "./screen";
import { Grid, CssBaseline } from "@material-ui/core";
import React from "react";
import StyleContext from "./stylecontext";
import { AlertProvider } from "./Alert.context";
// import axios from "axios";
import { ContextThemeProvider } from "./context/theme.context";
import { _admin_url } from "./config";
function App() {
  const [projectId, setProjectId] = React.useState("");
  let params = new URLSearchParams(window.location.search);
  // checkWithIdm("17dd3832-1351-4a1c-a0db-3798046adfdd");

  if (params.get("metadata_id")) {
    sessionStorage.setItem("metadataId", params.get("metadata_id"));
    sessionStorage.setItem("token", params.get("token"));
  }
  var metadataid = sessionStorage.getItem("metadataId");
  React.useEffect(() => {
    if (!Boolean(params.get("metadata_id"))) {
      window.location.replace(_admin_url);
    }
  }, []);
  React.useEffect(() => {
    // window.location.reload(true);

    var datas = {
      db_name: process.env.REACT_APP_DBNAME,
      entity: "projectvstools",
      filter: `projectvstools.metadataid =='${metadataid}'`,
      return_fields: "projectvstools",
    };

    // var config = {
    //   method: "post",
    //   url: process.env.REACT_APP_API_URL,
    //   header: { "content-Type": "application/json" },
    //   data: datas,
    // };
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(datas),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((res) => {
        console.log("res", res);
        setProjectId(res.result[0].projectid);
        sessionStorage.setItem("clientid", res.result[0].clientid);
        sessionStorage.setItem("projectid", res.result[0].projectid);
        sessionStorage.setItem("databasename", res.result[0].dbname);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [metadataid]);
  return (
    <>
      <AlertProvider>
        <StyleContext>
          <ContextThemeProvider>
            <CssBaseline>
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <CenteredTabs projectId={projectId}></CenteredTabs>
                </Grid>
              </Grid>
            </CssBaseline>
          </ContextThemeProvider>
        </StyleContext>
      </AlertProvider>
    </>
  );
}

export default App;
