// import { ThemeProvider } from '@emotion/react';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core";
import React from "react";
import { StylesProvider, createGenerateClassName } from "@material-ui/core";
export let Themes = {
  default: "default",
  dark: "dark",
};
export let ThemeContext = React.createContext({
  name: Themes.default,
  setTheme: () => null,
});

export const ContextThemeProvider = (props) => {
  const [state, setState] = React.useState({
    name: Themes.default,
  });

  const settheme = (props) => {
    setState({ ...props });
  };

  const Theme = () => {
    let currentThemeJson;

    //Geting the theme json for  the selected theme
    switch (state.name) {
      case Themes.default:
        currentThemeJson = require("../theme/default.json");
        break;

      default:
        currentThemeJson = require("../theme/default.json");
        break;
    }

    //Creating a Theme with json
    let currentTheme = createTheme(currentThemeJson);

    //Making theme work with responsive font size
    currentTheme = responsiveFontSizes(currentTheme);

    return currentTheme;
  };
  console.log(Theme());
  const generateClassName = createGenerateClassName({
    productionPrefix: "custom-material-ui-component",
    seed: "custom-material-ui-component",
  });

  return (
    <StylesProvider generateClassName={generateClassName} injectFirst>
      <ThemeContext.Provider value={{ ...state, settheme }}>
        <ThemeProvider theme={Theme()}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
};
