export const Dbname = process.env.REACT_APP_DBNAME;
export const Entity = process.env.REACT_APP_ENTITY;
export const Api_Url = process.env.REACT_APP_API_URL;
export const reportdbname = process.env.REACT_APP_REPORT_DBNAME;
export const reportentity = process.env.REACT_APP_REPORT_ENTITY;
export const _admin_url = process.env.REACT_APP_QDM_ADMIN_ENDPOINT;

export const query = {
  db_name: "ATP_Metadata_Dev",
  entity: "Report_Viewer",
  return_fields: "Report_Viewer",
};
