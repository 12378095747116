import * as React from "react";
import { Box, Tabs, Tab, Grid } from "@material-ui/core";
import { Dashboard, Cardview, Pdf } from "../../components";
import { NetworkReadCall } from "../../utils/network";
import * as config from "../../config";
import Stylecontent from "../../context/index";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { makeStyles } from "@material-ui/core/styles";
import NoDataCard from "../../components/nodatacard/NoDataCard";
import withNavBars from "../../HOCs/withNavBars";
const useStyles = makeStyles((theme) => ({
  root: {
    // fontFamily: "Quicksand, sans-serif !important",
    fontFamily: "TDS_bold !important",
    backgroundColor: theme.palette.secondary.light,
  },
  boxStyle: {
    backgroundColor: theme.palette.info.light,
    position: "sticky",
    top: 0,
    cursor: "pointer",
  },
  indicator: {
    backgroundColor: theme.palette.primary.dark,
    height: "10px",
    top: "45px",
  },
  tab: {
    color: theme.palette.primary.dark,
  },
}));
const CenteredTabs = (props) => {
  const styleprop = React.useContext(Stylecontent);
  const [value, setValue] = React.useState(0);
  const [reportList, setReportList] = React.useState([]);
  const [transition, setTransition] = React.useState(false);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setTransition(!transition);
    setValue(newValue);
  };
  // console.log(sessionStorage.getItem("token").split("."));
  // let List = sessionStorage.getItem("token").split(".");

  React.useEffect(() => {
    let res_data = NetworkReadCall({
      db_name: config.reportdbname,
      entity: config.reportentity,
      filter: `${config.reportentity}.projectid=='${props.projectId}'`,
      return_fields: `${config.reportentity}`,
    });
    res_data
      .then((res) => setReportList(res.result))
      .catch((err) => console.error(err));
  }, [props.projectId]);
  return (
    <>
      <Box
        sx={{ width: "100%", bgcolor: "background.paper" }}
        className={classes.boxStyle}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          TabIndicatorProps={{ className: classes.indicator }}
        >
          <Tab className={classes.tab} label={styleprop.tab1} />
          <Tab className={classes.tab} label={styleprop.tab2} />
          <Tab className={classes.tab} label={styleprop.tab3} />
        </Tabs>
      </Box>

      <SwitchTransition mode={"out-in"}>
        <CSSTransition
          key={transition}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="alert"
        >
          <div className="button-container">
            {props.projectId.length !== 0 ? (
              <Grid container style={{ marginTop: "10px" }}>
                <Grid item lg={12} sm={12} md={12} xs={12}>
                  {value === 0 && <Cardview data={reportList} />}
                  {value === 1 && <Dashboard data={reportList} />}
                  {value === 2 && <Pdf data={reportList} />}
                </Grid>
              </Grid>
            ) : (
              <NoDataCard />
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </>
  );
};
export default withNavBars(CenteredTabs);
