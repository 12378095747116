import React from "react";
import { Grid, Paper, IconButton } from "@material-ui/core";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import GridOnRoundedIcon from "@material-ui/icons/GridOnRounded";
import ViewListRoundedIcon from "@material-ui/icons/ViewListRounded";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import "./style.css";
import NoDataCard from "../nodatacard/NoDataCard";
import ListTable from "../listtable/ListTable";
import ListGrid from "../listgrid/ListGrid";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles((theme) => ({
  togglebutton: {
    "&.Mui-selected, &.Mui-selected:hover": {
      color: theme.palette.primary.dark,
      backgroundColor: theme.palette.info.light,
    },
  },
}));
const Dashboard = (props) => {
  const [alignment, setAlignment] = React.useState("card");
  const supersetReport = props.data.filter((e) => e.reporttype === "superset");
  const classes = useStyles();
  const [viewdetails, setViewDetails] = React.useState({
    data: {},
    view: false,
  });
  const handleView = React.useCallback((viewvalue) => {
    setViewDetails({
      view: true,
      data: viewvalue.url,
    });
    console.log(viewvalue);
  }, []);

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) setAlignment(newAlignment);
  };

  return (
    <>
      {supersetReport.length !== 0 ? (
        <Grid
          container
          justifyContent={"center"}
          style={{
            padding: !viewdetails.view ? "4px" : "0px",
          }}
        >
          {!viewdetails.view && (
            <Grid
              container
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              justifyContent="flex-end"
              style={{ marginBottom: "10px" }}
            >
              <ToggleButtonGroup
                size="small"
                exclusive
                value={alignment}
                onChange={handleAlignment}
                style={{ height: "30px" }}
              >
                <ToggleButton value="card" className={classes.togglebutton}>
                  <GridOnRoundedIcon style={{ fontSize: "15px" }} />
                </ToggleButton>
                <ToggleButton value="table" className={classes.togglebutton}>
                  <ViewListRoundedIcon style={{ fontSize: "15px" }} />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          )}
          <SwitchTransition mode={"out-in"}>
            <CSSTransition
              key={alignment}
              addEndListener={(node, done) => {
                node.addEventListener("transitionend", done, false);
              }}
              classNames="fade"
            >
              <div className="button-container">
                {
                  // View toggle button
                }
                <Grid container item lg={12} md={12} sm={12}>
                  {
                    // View the report in card
                  }
                  <Grid container item spacing={5} lg={12} md={12} sm={12}>
                    {!viewdetails.view &&
                      alignment === "card" &&
                      supersetReport.map((e, i) => {
                        return (
                          <ListGrid
                            key={i}
                            data={e}
                            index={i}
                            handleView={(e) => handleView(e)}
                          />
                        );
                      })}
                  </Grid>
                </Grid>
                {
                  // View the report in list
                }
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  style={{
                    width: "99vw",
                    maxHeight: "60vh",

                    overflowY: "auto !important",
                    padding: "0px 10px",
                  }}
                >
                  {!viewdetails.view && alignment === "table" && (
                    <Paper
                      style={{
                        border: "1px solid #DEE5EC",
                        borderRadius: "8px",
                      }}
                      elevation={0}
                    >
                      <Grid container item lg={12} md={12} sm={12} xl={12}>
                        {supersetReport.map((e, i) => {
                          return (
                            <ListTable
                              key={i}
                              data={e}
                              index={i}
                              report={supersetReport}
                              handleView={(e) => handleView(e)}
                            />
                          );
                        })}
                      </Grid>
                    </Paper>
                  )}
                </Grid>
              </div>
            </CSSTransition>
          </SwitchTransition>

          {
            // This will show the report table
          }
          <CSSTransition in={viewdetails.view} timeout={100} classNames="alert">
            <div
              style={{
                width: "95%",
              }}
            >
              {viewdetails.view && (
                <Grid container>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                          setViewDetails({ view: false, data: {} });
                        }}
                      >
                        <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
                      </IconButton>
                    </div>
                    <div class="container">
                      <div class="h_iframe">
                        <iframe
                          title={viewdetails.data.toString()}
                          src={viewdetails.data}
                          allowfullscreen
                          frameborder="0"
                          width="100%"
                          height="100%"
                        ></iframe>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )}
            </div>
          </CSSTransition>
        </Grid>
      ) : (
        <NoDataCard />
      )}
    </>
  );
};

export default Dashboard;
