import { Box, Typography } from "@material-ui/core";
import React from "react";
const NoDataCard = () => {
  return (
    <Box
      style={{
        display: "grid",
        placeContent: "center",
        placeItems: "center",
        height: "85vh",
      }}
    >
      <Box>
        <img
          src="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          alt="No Data"
        />
        <Typography style={{ textAlign: "center" }}>No Data</Typography>
      </Box>
    </Box>
  );
};
export default NoDataCard;
