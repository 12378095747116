export const getReport = async (reportid, readUrl) => {
  console.log("aa");
  try {
    let params = {
      db_name: process.env.REACT_APP_METADATA_DB_NAME,
      entity: "QDMReports",
      filter: `QDMReports.reportid=='${reportid}' && QDMReports.activestatus==true`,
      return_fields: "QDMReports",
    };
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const res = await fetch(readUrl, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(params),
      redirect: "follow",
    });
    const data = await res.json();
    return data;
  } catch (error) {
    let data = { Code: 1111, error: true };
    return data;
  }
};
