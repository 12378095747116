import React from "react";
import { Grid, Paper, Typography, IconButton } from "@material-ui/core";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import GridOnRoundedIcon from "@material-ui/icons/GridOnRounded";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ViewListRoundedIcon from "@material-ui/icons/ViewListRounded";
// import { Flexview } from "../index";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import "./style.css";
import Ainqaflexmon from "../AinqaFlex.js";
import NoDataCard from "../nodatacard/NoDataCard";
import ListTable from "../listtable/ListTable";
import ListGrid from "../listgrid/ListGrid";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles((theme) => ({
  togglebutton: {
    "&.Mui-selected, &.Mui-selected:hover": {
      color: theme.palette.primary.dark,
      backgroundColor: theme.palette.info.light,
    },
  },
}));
const Cardview = (props) => {
  const [alignment, setAlignment] = React.useState("card");
  const flexreport = props.data.filter((e) => e.reporttype === "flexmonster");
  const classes = useStyles();
  // console.log(styleprop);
  console.log({ flexreport });
  const [viewdetails, setViewDetails] = React.useState({
    data: {},
    view: false,
    reportid: "",
  });
  const handleView = React.useCallback((viewvalue) => {
    console.log(viewvalue);
    setViewDetails({
      view: true,
      data: viewvalue,
      reportid: viewvalue.reportid,
    });
  }, []);
  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) setAlignment(newAlignment);
  };
  return (
    <React.Fragment>
      {flexreport.length !== 0 ? (
        <Grid container style={{ padding: !viewdetails.view ? "4px" : "0px" }}>
          <Grid item xl={12} sm={12} md={12} lg={12} xs={12}>
            {!viewdetails.view && (
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                justifyContent="flex-end"
                style={{ marginBottom: "10px" }}
              >
                <ToggleButtonGroup
                  size="small"
                  exclusive
                  value={alignment}
                  onChange={handleAlignment}
                  style={{ height: "30px" }}
                >
                  <ToggleButton value="card" className={classes.togglebutton}>
                    <GridOnRoundedIcon style={{ fontSize: "15px" }} />
                  </ToggleButton>
                  <ToggleButton value="table" className={classes.togglebutton}>
                    <ViewListRoundedIcon style={{ fontSize: "15px" }} />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            )}
            <SwitchTransition mode={"out-in"}>
              <CSSTransition
                key={alignment}
                addEndListener={(node, done) => {
                  node.addEventListener("transitionend", done, false);
                }}
                classNames="fade"
              >
                <div>
                  {
                    // View toggle button
                  }
                  {
                    // View the report in card
                  }
                  {flexreport.length !== 0 ? (
                    <Grid container item lg={12} md={12} sm={12}>
                      <Grid container item spacing={5} lg={12} md={12} sm={12}>
                        {!viewdetails.view &&
                          alignment === "card" &&
                          flexreport.map((e, i) => {
                            console.log("aaa", props.casbinData);

                            return (
                              <ListGrid
                                key={i}
                                data={e}
                                index={i}
                                handleView={(e) => handleView(e)}
                              />
                            );
                          })}
                      </Grid>
                      {
                        // View the report in list
                      }
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        style={{
                          maxHeight: "60vh",
                          overflowY: "auto !important",
                          padding: "0px 10px",
                        }}
                      >
                        {!viewdetails.view && alignment === "table" && (
                          <Paper
                            style={{
                              border: "1px solid #DEE5EC",
                              borderRadius: "8px",
                            }}
                            elevation={0}
                          >
                            <Grid
                              container
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              xl={12}
                            >
                              {flexreport.map((e, i) => {
                                return (
                                  <ListTable
                                    key={i}
                                    data={e}
                                    index={i}
                                    report={flexreport}
                                    handleView={(e) => handleView(e)}
                                  />
                                );
                              })}
                            </Grid>
                          </Paper>
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item xl={12} lg={12} sm={12} md={12}>
                      <img
                        src="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        alt="No Data"
                      />
                      <Typography style={{ textAlign: "center" }}>
                        No Data
                      </Typography>
                    </Grid>
                  )}
                </div>
              </CSSTransition>
            </SwitchTransition>
            {
              // This will show the report table
            }
            <CSSTransition
              in={viewdetails.view}
              timeout={100}
              classNames="alert"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  position: "relative",
                  width: "95%",
                  margin: "auto",
                }}
              >
                {viewdetails.view && (
                  <Grid container>
                    <Grid item lg={12} xs={12} md={12}>
                      <div>
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={() => {
                            setViewDetails({ view: false, data: {} });
                          }}
                        >
                          <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
                        </IconButton>
                      </div>
                      <div style={{ margin: "auto" }}>
                        <Ainqaflexmon data={viewdetails.reportid} />
                      </div>
                    </Grid>
                  </Grid>
                )}
              </div>
            </CSSTransition>
          </Grid>
        </Grid>
      ) : (
        <NoDataCard />
      )}
    </React.Fragment>
  );
};

export default Cardview;
