import React from "react";
import { Grid, Paper, Typography, IconButton } from "@material-ui/core";
import StyleContent from "../../context/index";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  iconbutton: {
    color: theme.palette.primary.dark,
  },
}));
const ListGrid = ({ index, data, handleView }) => {
  const classes = useStyles();
  const styleprop = React.useContext(StyleContent);
  return (
    <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
      <Paper
        elevation={0}
        style={{
          padding: "10px",
          border: "1.45px solid #DEE5EC",
          // minWidth: "300px",
        }}
      >
        <Grid container item alignItems={"center"}>
          <Grid item lg={11} md={11} sm={11} xs={11}>
            <Typography
              variant="h6"
              style={{
                textTransform: "capitalize",
                // fontFamily: "Quicksand, sans-serif !important",
                fontFamily: "TDS_bold !important",

                fontSize: "16px",
              }}
            >
              {data.reportname}
            </Typography>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={1}>
            <IconButton
              size="small"
              className={classes.iconbutton}
              // style={{
              //   color: styleprop.visibilityIconColor,
              // }}
              onClick={(evt) => handleView(data)}
            >
              <VisibilityIcon
                style={{
                  fontSize: styleprop.visibilityIconSize.toString(),
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
export default ListGrid;
