import React from "react";
import { Grid, Typography, IconButton, Divider } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Stylecontent from "../../context/index";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  iconbutton: {
    color: theme.palette.primary.dark,
  },
}));

const ListTable = ({ data, index, handleView, report }) => {
  const classes = useStyles();
  const styleprop = React.useContext(Stylecontent);
  console.log(styleprop);
  return (
    <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
      <Grid
        container
        item
        alignItems="center"
        justifyContent="space-between"
        style={{ padding: "5px 20px" }}
      >
        <Grid item lg={10} md={10} sm={10} xs={10}>
          <Typography
            variant="h6"
            color="text.primary"
            style={{
              textTransform: "capitalize",
              // fontFamily: "Quicksand, sans-serif !important",
              fontFamily: "TDS_bold !important",
              fontSize: "16px",
            }}
          >
            {data.reportname}
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          item
          lg={1}
          md={1}
          sm={1}
          xs={1}
        >
          <IconButton
            size="small"
            className={classes.iconbutton}
            // style={{
            //   color: styleprop.visibilityIconColor,
            // }}
            onClick={(evt) => handleView(data)}
          >
            <VisibilityIcon
              style={{
                fontSize: styleprop.visibilityIconSize.toString(),
              }}
            />
          </IconButton>
        </Grid>
      </Grid>
      {report.length - 1 !== index && <Divider variant="fullWidth" />}
    </Grid>
  );
};

export default ListTable;
