import React from "react";
import {
  Grid,
  Paper,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import GridOnRoundedIcon from "@material-ui/icons/GridOnRounded";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ViewListRoundedIcon from "@material-ui/icons/ViewListRounded";
import "./style.css";
import { makeStyles } from "@material-ui/core/styles";
// import axios from "axios";
// import PDFViewer from "pdf-viewer-reactjs";
import NoDataCard from "../nodatacard/NoDataCard";
import ListTable from "../listtable/ListTable";
import ListGrid from "../listgrid/ListGrid";
import { AlertContext } from "../../Alert.context";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    ".MuiInputBase-input-MuiOutlinedInput-input": {
      padding: "0px",
    },
  },
  textfield: {
    color: theme.palette.primary.dark,
  },
  togglebutton: {
    "&.Mui-selected, &.Mui-selected:hover": {
      color: theme.palette.primary.dark,
      backgroundColor: theme.palette.info.light,
    },
  },
}));

const Pdf = (props) => {
  const alertMessage = React.useContext(AlertContext);
  const [alignment, setAlignment] = React.useState("card");
  const jasperReport = props.data.filter((e) => e.reporttype === "jasper");
  const [listParams, setListParams] = React.useState([]);
  const [finalList, setFinallist] = React.useState({});
  const [pdfReportId, setPdfReportId] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [pdf, setPdf] = React.useState("");
  const [viewdetails, setViewDetails] = React.useState({
    data: {},
    view: false,
  });
  const classes = useStyles();
  const handleView = React.useCallback((viewvalue) => {
    console.log(viewvalue.inputparams, viewvalue);
    if (viewvalue.inputparams.length === 0) {
      setViewDetails({
        view: true,
        data: viewvalue,
      });
    } else {
      handleClickOpen(viewvalue.inputparams, viewvalue.reportPdfId);
    }
  }, []);

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) setAlignment(newAlignment);
  };

  const handleClickOpen = (getParams, reportid) => {
    setOpen(true);
    setListParams(getParams);
    setPdfReportId(reportid);
  };

  const applyFunction = async () => {
    console.log(listParams, finalList);
    if (listParams.length === Object.keys(finalList).length) {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      await fetch(
        "https://jasper.reports.dev.ainqaplatform.in/api/generateReport",
        {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            reportid: pdfReportId,
            inputparams: finalList,
            result: [],
          }),
          redirect: "follow",
        }
      )
        .then((response) => response.json())
        .then((res) => {
          console.log(res.downloadUrl);
          setViewDetails({
            view: true,
            data: res.downloadUrl,
          });
          fetch(res.downloadUrl)
            .then((res) => res.blob())
            .then((response) => {
              //Create a Blob from the PDF Stream
              const file = new Blob([response], {
                type: "application/pdf",
              });
              //Build a URL from the file
              const fileURL = URL.createObjectURL(file);
              //Open the URL on new Window
              setPdf(fileURL);
              //window.open(fileURL);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((err) => console.error(err));
      setOpen(false);
      console.log("qawsed", listParams, finalList);
      setListParams([]);
      setFinallist({});
    } else {
      alertMessage.setAlert({
        ...alertMessage,
        open: true,
        severity: "error",
        message: "Required Field Are Empty!",
      });
    }
  };
  console.log("qawsed", listParams, finalList);

  const handleClose = () => {
    setOpen(false);
  };
  const changeHandle = (evt) => {
    console.log(evt.target.name, evt.target.value);
    Object.assign(finalList, { [evt.target.name]: evt.target.value });
    setFinallist(finalList);
  };
  console.log(finalList);
  return (
    <>
      {jasperReport.length !== 0 ? (
        <Grid
          container
          justifyContent={"center"}
          style={{
            padding: !viewdetails.view ? "4px" : "0px",
          }}
        >
          {!viewdetails.view && (
            <Grid
              container
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              justifyContent="flex-end"
              style={{ marginBottom: "10px" }}
            >
              <ToggleButtonGroup
                size="small"
                exclusive
                value={alignment}
                onChange={handleAlignment}
                style={{ height: "30px" }}
              >
                <ToggleButton value="card" className={classes.togglebutton}>
                  <GridOnRoundedIcon style={{ fontSize: "15px" }} />
                </ToggleButton>
                <ToggleButton value="table" className={classes.togglebutton}>
                  <ViewListRoundedIcon style={{ fontSize: "15px" }} />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          )}
          <SwitchTransition mode={"out-in"}>
            <CSSTransition
              key={alignment}
              addEndListener={(node, done) => {
                node.addEventListener("transitionend", done, false);
              }}
              classNames="fade"
            >
              <div className="button-container">
                {
                  // View toggle button
                }
                <Grid container item lg={12} md={12} sm={12}>
                  {
                    // View the report in card
                  }
                  <Grid container item spacing={5} lg={12} md={12} sm={12}>
                    {!viewdetails.view &&
                      alignment === "card" &&
                      jasperReport.map((e, i) => {
                        return (
                          <ListGrid
                            key={i}
                            data={e}
                            index={i}
                            handleView={(e) => handleView(e)}
                          />
                        );
                      })}
                  </Grid>
                </Grid>
                {
                  // View the report in list
                }
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  style={{
                    width: "99vw",
                    maxHeight: "60vh",

                    overflowY: "auto !important",
                    padding: "0px 10px",
                  }}
                >
                  {!viewdetails.view && alignment === "table" && (
                    <Paper
                      style={{
                        border: "1px solid #DEE5EC",
                        borderRadius: "8px",
                      }}
                      elevation={0}
                    >
                      <Grid container item lg={12} md={12} sm={12} xl={12}>
                        {jasperReport.map((e, i) => {
                          return (
                            <ListTable
                              key={i}
                              data={e}
                              index={i}
                              report={jasperReport}
                              handleView={(e) => handleView(e)}
                            />
                          );
                        })}
                      </Grid>
                    </Paper>
                  )}
                </Grid>
              </div>
            </CSSTransition>
          </SwitchTransition>

          {
            // This will show the report table
          }
          <CSSTransition in={viewdetails.view} timeout={100} classNames="alert">
            <div
              style={{
                width: "95%",
              }}
            >
              {viewdetails.view && (
                <Grid container>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                          setViewDetails({ view: false, data: {} });
                        }}
                      >
                        <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
                      </IconButton>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "grid",
                          width: "100%",
                        }}
                      >
                        <embed
                          src={pdf}
                          width="100%"
                          style={{ height: "80vh" }}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )}
            </div>
          </CSSTransition>
        </Grid>
      ) : (
        <NoDataCard />
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose()}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Get Input Params"}</DialogTitle>
        <DialogContent>
          {console.log("listparams", listParams)}
          {listParams.map(
            (e, i) =>
              e.includes("@") && (
                <div key={i}>
                  <Typography>{e}</Typography>
                  <TextField
                    className={classes.root}
                    name={e}
                    fullWidth={true}
                    onChange={changeHandle}
                  ></TextField>
                </div>
              )
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={applyFunction}>Apply</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Pdf;
